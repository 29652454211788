import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { shippingFormikProps } from "../app/(navbar)/listings/list_item";

const initialState: shippingFormikProps = {
  pickupOrDropoff: "pickup",
  buyOrOwn: "buy",
};

const shippingOptionsSlice = createSlice({
  name: "shippingOptions",
  initialState,
  reducers: {
    setShippingOptions: (state, action: PayloadAction<shippingFormikProps>) => {
      return action.payload;
    },
  },
});

export const { setShippingOptions } = shippingOptionsSlice.actions;
export default shippingOptionsSlice.reducer;
