import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserState } from "../@types/common_types";

const initialState: UserState = {
  user: undefined,
  stats: undefined,
  id: "",
  name: "",
  email: "",
  username: "",
  otpCode: "",
  otpId: "",
  phoneNumber: "",
  address: null,
  isVerified: false,
  isOnboarding: false,
  hasCreatedListing: false,
  ref: "",
  hasReviewed: false,
  following: [],
  followers: [],
  lastCheckedEbayDelisted: undefined,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Partial<UserState>>) => {
      return { ...state, ...action.payload };
    },
    clearUser: () => {
      return { ...initialState };
    },
    setHasCreatedListing: (state, action: PayloadAction<boolean>) => {
      return { ...state, hasCreatedListing: action.payload };
    },
  },
});

export const { setUser, clearUser, setHasCreatedListing } = userSlice.actions;
export default userSlice.reducer;
