import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PostInventoryProduct } from "../@types/common_types";

interface AddItemSelectedInventoryState {
    products: PostInventoryProduct[]
}

const initialState: AddItemSelectedInventoryState = {
    products: [],
}

const addItemSelectedInventorySlice = createSlice({
    name: "addItemSelectedInventory",
    initialState,
    reducers: {
        addProduct: (state, action: PayloadAction<PostInventoryProduct>) => {
            let newState = [...state.products, action.payload];
            state.products = newState;
        },
        removeProduct: (state, action: PayloadAction<string>) => {
            state.products = state.products.filter(product => product.listing_id !== action.payload);
        },
        setProducts: (state, action: PayloadAction<PostInventoryProduct[]>) => {
            state.products = action.payload;
        },
    }
})

export const { addProduct, removeProduct, setProducts } = addItemSelectedInventorySlice.actions;
export default addItemSelectedInventorySlice.reducer;
