import { emptyPostInventoryProduct, PostInventoryProduct } from "../../../../@types/common_types";
import { baseUrl } from "../../../../lib/api/constants";
import { GetInventoryProduct } from "../../../../lib/api/Product";

export const getInventoryItem = async (inventoryId: string): Promise<PostInventoryProduct | null> => {
  const url = new URL("/" + "inventory", baseUrl);
  url.searchParams?.set("inventory" + "_id", inventoryId);
  try {
    const response = await fetch(url, { credentials: "include" });
    if (response.status === 200) {
      const data = await response.json();
      return convertToPostInventoryProduct(data.products[0]);
    } else {
      throw new Error(`An unknown error has occurred. Status code: ${response.status}`);
    }
  } catch (error) {
    console.error("Error during useProduct: ", error);
    return null;
  }
};

export const updateInventoryOneClick = async (inventory: PostInventoryProduct): Promise<string | null> => {
  type PostInventoryResponse = {
    ids: {
      listing_id: string;
      draft_id: string;
      inventory_id: string;
      sku: string;
    }[];
  };

  try {
    const requestBody = JSON.stringify(inventory);
    const url = new URL("/inventory", baseUrl);
    const resp = await fetch(url, {
      method: "PATCH",
      body: requestBody,
      credentials: "include",
    });
    if (!resp.ok) {
      const body = await resp.text();
      return body;
    }
    return null;
  } catch (e) {
    if (e instanceof Error) {
      return e.toString();
    }
    return `An unknown error occured while updating the resource.`;
  }
};

export const checkEbayPolicies = async (): Promise<boolean> => {
  type EbayPolicyResponse = {
    policies: {
      type: string;
      values: {
        value: string;
        displayName: string;
      }[];
    }[];
    marketplace: string;
    marketplaceId: string;
  };
  try {
    const url = new URL("/integrations/policies/default", baseUrl);
    url.searchParams?.set("marketplace", "EBAY");
    const resp = await fetch(url, {
      method: "GET",
      body: null,
      credentials: "include",
    });
    if (resp.status === 200) {
      const data: EbayPolicyResponse = await resp.json();
      const policies = data.policies;
      if (policies.length < 4) {
        return false;
      }
      if (policies.some((policy) => (policy.values.length < 1 ? false : true))) {
        return true;
      }
      return false;
    } else {
      console.log("Error: ", resp.status);
      return false;
    }
  } catch (e) {
    if (e instanceof Error) {
      return false;
    }
    return false;
  }
};

function convertToPostInventoryProduct(product: GetInventoryProduct | null): PostInventoryProduct {
  if (product === null) {
    return emptyPostInventoryProduct;
  } else {
    return {
      inventory_id: product.inventory_id,
      listing_id: product.listing_id,
      restricted_allocation: product.restricted_allocation,
      created_at: product.created_at,
      updated_at: product.updated_at,
      product_name: product.product_name,
      quantity: product.quantity,
      platforms: {
        marketplaces: product.platforms.marketplaces
          ? product.platforms.marketplaces.map((marketplace) => ({
              marketplace: marketplace.marketplace,
              marketplace_ids: marketplace.marketplace_ids || [], // add null check here
              active: marketplace.active,
            }))
          : [],
      },
      tempInventory: {
        marketplace_shipping: [], // add null check here
      },
      type: product.type,
      general_information: {
        has_variation: product.general_information.has_variation,
        has_brand_name: product.general_information.has_brand_name,
        imported: false, // Add the 'imported' property here
        has_pid: product.general_information.has_pid,
        brand_name: product.general_information.brand_name,
        sku: product.general_information.sku,
        external_pid: product.general_information.external_pid,
        external_pid_type: product.general_information.external_pid_type,
        model_number: product.general_information.model_number,
        description: product.general_information.description,
        notes: product.general_information.notes,
        item_purchase_date: product.general_information.item_purchase_date,
        size: product.general_information.size,
      },
      categories: {
        category_type_array: product.categories.category_type_array
          ? product.categories.category_type_array.map((category) => ({
              category_id: category.category_id || [],
              category_name: category.category_name,
              marketplace: category.marketplace,
            }))
          : [],
      },
      images: {
        image_urls: product.images.image_urls
          ? product.images.image_urls.map((image) => ({
              image_url: image.image_url,
              selected: image.selected,
            }))
          : [],
      },
      product_specifications: {
        marketplace_specs: product.product_specifications.marketplace_specs
          ? product.product_specifications.marketplace_specs.map((spec) => ({
              marketplace: spec.marketplace,
              marketplace_id: spec.marketplace_id,
              specifications: {
                parent: spec.specifications.parent,
                values: spec.specifications.values || [], // add null check here
                aspects: spec.specifications.aspects ? Object.fromEntries(
                  Object.entries(spec.specifications.aspects).map(([key, aspect]) => [
                    key,
                    {
                      ...aspect,
                      nestedValues: aspect.nestedValues || [],
                    },
                  ]),
                ) : {},
              },
            }))
          : [],
      },
      variations: {
        variation_type: product.variations.variation_type,
        size: product.variations.size,
        color: product.variations.color,
        style: product.variations.style,
      },
      shipping: {
        item_dimensions: product.shipping.item_dimensions,
        package_dimensions: product.shipping.package_dimensions,
        weight: product.shipping.weight,
        warehouse_location: product.shipping.warehouse_location || [],
        marketplace_shipping: product.shipping.marketplace_shipping ?? []
      },
      pricing: {
        condition: product.pricing.condition,
        item_cost: product.pricing.item_cost,
        currency: product.pricing.currency,
        marketplace_pricing: product.pricing.marketplace_pricing
          ? product.pricing.marketplace_pricing.map((pricing) => ({
              marketplace: pricing.marketplace,
              shipping_services: pricing.shipping_services || [], // change property name to 'shipping_services'
              currency: pricing.currency,
              presale_price: pricing.presale_price,
              listing_price: pricing.listing_price || null,
              platform_fees: pricing.platform_fees || null,
              default_platform_fees: pricing.default_platform_fees || null,
            }))
          : [],
        default_listing_price: product.pricing.default_listing_price,
        amazon_fba: product.pricing.amazon_fba,
      },
    };
  }
}

export const emptyMarketplaces: {
  marketplace: "AMAZON" | "EBAY" | "FACEBOOK" | "MERCARI" | "DEPOP" | "OFFERUP";
  marketplace_ids: string[];
  active: boolean;
}[] = [];

