import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserState } from "../@types/common_types";

const initialState: UserState = {
  user: undefined,
  stats: undefined,
  id: "",
  name: "",
  email: "",
  username: "",
  otpCode: "",
  otpId: "",
  phoneNumber: "",
  address: null,
  isVerified: false,
  isOnboarding: false,
  hasCreatedListing: false,
  ref: "",
  hasReviewed: false,
  following: [],
  followers: [],
  lastCheckedEbayDelisted: undefined,
};

const newUserSlice = createSlice({
  name: "newUser",
  initialState,
  reducers: {
    setNewUser: (state, action: PayloadAction<Partial<UserState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearNewUser: () => {
      return { ...initialState };
    },
    setHasCreatedListingNewUser: (state, action: PayloadAction<boolean>) => {
      return { ...state, hasCreatedListing: action.payload };
    },
    setName: (state, action: PayloadAction<string>) => {
      if (state.user) {
        state.user.name = action.payload;
      }
      //return { ...state, username: action.payload };
    },
    setPhoneNumber : (state, action: PayloadAction<string>) => {
      if (state.user) {
        state.user.phone_number = action.payload;
      }
      //return { ...state, phoneNumber: action.payload };
    },
    setProfilePictureUrl: (state, action: PayloadAction<string>) => {
      if (state.user) {
        state.user.profile_picture_url = action.payload;
      }
    },
    setOptInLeaderboard: (state, action: PayloadAction<boolean>) => {
      if (state.user) {
        state.user.opt_in_leaderboard = action.payload;
      }
    },
  }
});

export const { setNewUser, clearNewUser, setHasCreatedListingNewUser, setName, setPhoneNumber, setProfilePictureUrl, setOptInLeaderboard } = newUserSlice.actions;

export default newUserSlice.reducer;
