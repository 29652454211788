import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ClientSideState {
  selectedMenuItem: string;
  inventoryDropdownOpen: boolean;
}

const initialState: ClientSideState = {
  selectedMenuItem: 'Home',
  inventoryDropdownOpen: false,
};

const clientSideSlice = createSlice({
  name: 'clientSide',
  initialState,
  reducers: {
    setSelectedMenuItem: (state, action: PayloadAction<string>) => {
      state.selectedMenuItem = action.payload;
    },
    setInventoryDropdownOpen: (state, action: PayloadAction<boolean>) => {
      state.inventoryDropdownOpen = action.payload;
    },
  },
});

export const { setSelectedMenuItem, setInventoryDropdownOpen } = clientSideSlice.actions;
export default clientSideSlice.reducer;
