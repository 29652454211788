
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PlatformsState {
  selectedPlatforms: string[];
}

const initialState: PlatformsState = {
  selectedPlatforms: [],
};

const platformsSlice = createSlice({
  name: 'platforms',
  initialState,
  reducers: {
    setPlatform(state, action: PayloadAction<string[]>) {
      state.selectedPlatforms = action.payload;
    }
  },
});

export const { setPlatform } = platformsSlice.actions;
export default platformsSlice.reducer;
