import { configureStore } from "@reduxjs/toolkit";
import inventoryReducer from "./features/inventory-slice";
import extensionMarketplacesReducer from "./features/extension-marketplace-slice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { enableMapSet } from "immer";
import registerReducer from "./features/seller_slice";
import routerReducer from "./features/router_slice";
import itemDataReducer from "./slices/inventory_slice";
import platformsReducer from "./slices/platform_slice";
import addItemSelectedInventoryReducer from "./add_item_selected_inventory_slice";
import newInventoryReducer from "./new_inventory_slice";
import shippingOptionReducers from "./shipping_options_slice";
import userReducer from "./user_slice";
import newUserReducer from "./new_user_slice";
import clientSideReducer from "./slices/client_side_slice"; // Add this import

enableMapSet();

const inventoryPersistConfig = {
  key: "inventory",
  storage,
};

const extensionMarketplacesPersistConfig = {
  key: "extensionMarketplaces",
  storage,
};

const addItemSelectedInventoryPersistConfig = {
  key: "addItemSelectedInventory",
  storage,
};

const newInventoryPersistConfig = {
  key: "newInventory",
  storage,
};

const shippingOptionsPersistConfig = {
  key: "shippingOption",
  storage,
};

const platformsPersistConfig = {
  key: "platforms",
  storage,
};

const userPersistConfig = {
  key: "user",
  storage,
};

const newUserPersistConfig = {
  key: "newUser",
  storage,
};

const clientSidePersistConfig = {
  key: "clientSide",
  storage,
};

const persistedInventoryReducer = persistReducer(inventoryPersistConfig, inventoryReducer);
const persistedExtensionMarketplaceReducer = persistReducer(
  extensionMarketplacesPersistConfig,
  extensionMarketplacesReducer,
);
const persistedAddItemSelectedInventoryReducer = persistReducer(
  addItemSelectedInventoryPersistConfig,
  addItemSelectedInventoryReducer,
);
const persistedNewInventoryReducer = persistReducer(newInventoryPersistConfig, newInventoryReducer);
const persistedShippingOptionsReducer = persistReducer(shippingOptionsPersistConfig, shippingOptionReducers);
const persistedPlatformsReducer = persistReducer(platformsPersistConfig, platformsReducer);
const persistedUserReducer = persistReducer(userPersistConfig, userReducer);
const persistedNewUserReducer = persistReducer(newUserPersistConfig, newUserReducer);
const persistedClientSideReducer = persistReducer(
  clientSidePersistConfig,
  clientSideReducer
);

export const store = configureStore({
  reducer: {
    inventory: persistedInventoryReducer,
    item: itemDataReducer,
    platforms: persistedPlatformsReducer,
    // inventory: inventoryReducer,
    router: routerReducer,
    register: registerReducer,
    extensionMarketplaces: persistedExtensionMarketplaceReducer,
    addItemSelectedInventory: persistedAddItemSelectedInventoryReducer,
    newInventory: persistedNewInventoryReducer,
    shippingOptions: persistedShippingOptionsReducer,
    user: persistedUserReducer,
    newUser: persistedNewUserReducer,
    clientSide: persistedClientSideReducer, // Add this line
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
