import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptyPostInventoryProduct, PostInventoryProduct } from "../@types/common_types";

interface newInventoryState {
  items: PostInventoryProduct[];
}

const initialState: newInventoryState = {
  items: [emptyPostInventoryProduct],
};

const newInventorySlice = createSlice({
  name: "newInventory",
  initialState,
  reducers: {
    setNewInventory: (state, action: PayloadAction<PostInventoryProduct[]>) => {
      state.items = action.payload;
    },
    addNewInventory: (state, action: PayloadAction<PostInventoryProduct>) => {
      state.items.push(action.payload);
    }
  },
});

export const { setNewInventory } = newInventorySlice.actions;
export default newInventorySlice.reducer;
