import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { emptyPostInventoryProduct, PostInventoryProduct } from "@/src/@types/common_types";

interface InventoryState {
  inventoryData: PostInventoryProduct | null;
  images: string[];
  moreInfo: string;
  condition: string;
  previous: string;
  specsLoading: boolean;
  putLoading: boolean;
  suggestedPriceComparisons: any[];
}

const initialState: InventoryState = {
  inventoryData: emptyPostInventoryProduct,
  images: [] as string[],
  moreInfo: "", // describe the product in more detail
  condition: "", // new with tags, new in box, new, pre-owned
  previous: "createListing",
  specsLoading: false,
  putLoading: false,
  suggestedPriceComparisons: [] as any,
};

const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    setInventory: (state, action: PayloadAction<Partial<typeof initialState>>) => {
      return { ...state, ...action.payload };
    },
    // You can add more specific actions here if needed
    setImages: (state, action: PayloadAction<string[]>) => {
      state.images = action.payload;
    },
    setMoreInfo: (state, action: PayloadAction<string>) => {
      state.moreInfo = action.payload;
    },
    setCondition: (state, action: PayloadAction<string>) => {
      state.condition = action.payload;
    },
    setPrevious: (state, action: PayloadAction<string>) => {
      state.previous = action.payload;
    },
    setSpecsLoading: (state, action: PayloadAction<boolean>) => {
      state.specsLoading = action.payload;
    },
    setPutLoading: (state, action: PayloadAction<boolean>) => {
      state.putLoading = action.payload;
    },
    setSuggestedPriceComparisons: (state, action: PayloadAction<any[]>) => {
      state.suggestedPriceComparisons = action.payload;
    },
    updateInventory(state, action: PayloadAction<Partial<PostInventoryProduct>>) {
      if (state.inventoryData) {
        state.inventoryData = { ...state.inventoryData, ...action.payload };
      }
    },
  },
});

export const {
  setInventory,
  setImages,
  setMoreInfo,
  setCondition,
  setPrevious,
  setSpecsLoading,
  setPutLoading,
  setSuggestedPriceComparisons,
  updateInventory,
} = inventorySlice.actions;

export default inventorySlice.reducer;
